import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import {
  CircleMarker,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import { FaMapPin } from "react-icons/fa";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { BottomTabs, Header, SmallScreenHeader } from "../../components";
import "./addresses.css";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { APPDATAURLS, GOOGLEMAPSAPIKEY } from "../../utils/APPADATAURLS";
import { UserContext } from "../../contexts";
import { LoginPage } from "../Auth";
import { CustomMarker } from "../../components/markers";
import { useUserLocation } from "../../hooks";

// Map box
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOXACCESSTOKEN } from "../../utils/APPADATAURLS";
import mapboxgl from "mapbox-gl";

const myCustomColour = "#583470";

const markerHtmlStyles = `
  width: 3rem;
  height: 3rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF`;

const icon = L.divIcon({
  className: "my-custom-pin",
  iconAnchor: [0, 24],
  labelAnchor: [-6, 0],
  popupAnchor: [0, -36],
  html: `<span style="background-color: ${myCustomColour};${markerHtmlStyles}" />`,
});

const icon1 = L.divIcon({
  className: "my-custom-pin",
  iconAnchor: [0, 24],
  labelAnchor: [-6, 0],
  popupAnchor: [0, -36],
  html: `<span style="background-color: red;${markerHtmlStyles}" />`,
});

const DeliveryMap = () => {
  const { id } = useParams();

  const [delivery, setDelivery] = useState({
    error: false,
    loading: false,
    message: "",
    results: null,
  });

  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLEMAPSAPIKEY,
  });

  const [directionsService, setDirectionsService] = useState(null);
  const [directionsDisplay, setDirectionsDisplay] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  let [isDisplayed, setIsDisplayed] = useState(false);
  useEffect(() => {
    setDirectionsDisplay(null);
    if (map) {
      setDirectionsService(new window.google.maps.DirectionsService());
      setDirectionsDisplay(new window.google.maps.DirectionsRenderer());
    }

    if (directionsDisplay) {
      setIsDisplayed(true);
      directionsDisplay.setMap(map);
      directionsDisplay.setOptions({ suppressMarkers: true });
      //showDirection(delivery.results.products[0].latitude,delivery.results.products[0].longitude);
      //setIsUpdated(!isUpdated);
    }
  }, [map, isUpdated]);

  useEffect(() => {
    if (delivery.results && directionsDisplay) {
      //showDirection(delivery.results.products[0].latitude,delivery.results.products[0].longitude);
      setIsDisplayed(false);
    }
  }, [delivery.results]);

  const showDirection = (lat, lng) => {
    setIsUpdated(!isUpdated);
    if (!map) {
    }
    var haight = new window.google.maps.LatLng(coordinates[0], coordinates[1]);
    var oceanBeach = new window.google.maps.LatLng(
      parseFloat(lat),
      parseFloat(lng)
    );
    var mapOptions = {
      zoom: 14,
      center: haight,
    };

    var request = {
      origin: haight,
      destination: oceanBeach,
      // Note that JavaScript allows us to access the constant
      // using square brackets and a string value as its
      // "property."
      travelMode: window.google.maps.TravelMode["DRIVING"],
    };
    directionsService.route(request, function (response, status) {
      if (status == "OK") {
        directionsDisplay.setDirections(response);
      }
    });
  };

  const [userData, setUserData] = useContext(UserContext);

  const [coordinates, setCoordinates] = useState([0, 0]);
  const [errors, setErrors] = useState({
    state: false,
    message: "",
  });
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        async (position) => {
          setCoordinates([position.coords.latitude, position.coords.longitude]);
        },
        (error) => {
          setErrors({
            state: true,
            message: "An error occurred, try again later.",
          });
          //console.log(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setErrors({
        state: true,
        message: "OOPS!! Location services not supported!",
      });
    }
  };

  const fetchDelivery = async () => {
    let userID = userData?.data;
    let data = JSON.parse(localStorage.getItem("delivery"));
    //console.log(data);

    userID = data?.data;

    fetch(
      `${APPDATAURLS.getDeliveryGuyDelivery}${userID.id}&deliveryID=${id}`,
      {
        method: "GET",
      }
    )
      .then((req) => req.json())
      .then((response) => {
        setDelivery({ ...delivery, results: response.data });
        //console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserLocation();
    if (userData?.data) {
      fetchDelivery();
    }
  }, [userData]);

  const location = useUserLocation();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  useEffect(() => {
    if (userData.loggedIn && mapContainerRef.current) {
      mapboxgl.accessToken = MAPBOXACCESSTOKEN;
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/outdoors-v11", // streets-v12 or light-v10 or outdoors-v11
        center: [location.coordinates.longitude, location.coordinates.latitude],
        zoom: 14,
      });

      let popup = new mapboxgl.Popup().setText("Me").addTo(mapRef.current);

      new mapboxgl.Marker({
        color: "green",
        label: "Me",
        title: "Me",
      })
        .setLngLat([
          location.coordinates.longitude,
          location.coordinates.latitude,
        ])
        //.setLngLat([36.814841, -1.2836753])
        .addTo(mapRef.current)
        .setPopup(popup);

      let lngLats = [[
        location.coordinates.longitude,
        location.coordinates.latitude,
      ]];

      if (delivery.results?.products) {
        delivery.results.products.map((item, index) => {
          // console.log(item.latitude);

          let popup = new mapboxgl.Popup()
            .setText(item.productName)
            .addTo(mapRef.current);

          lngLats = [
            ...lngLats,
            [item?.longitude ?? 36.8216763, item?.latitude ?? -1.2830663],
          ];

          new mapboxgl.Marker({
            color: "var(--app-color)",
          })
            .setLngLat([
              item?.longitude ?? 36.8216763,
              item?.latitude ?? -1.2830663,
            ])
            .addTo(mapRef.current)
            .setPopup(popup);
        });

        

        mapRef.current.on("load", () => {
          if (mapRef.current.getSource(`delivery-route`)) {
            mapRef.current
              .removeLayer(`delivery-route`)
              .removeSource(`delivery-route`);
          }

          mapRef.current.addSource(`delivery-route`, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: lngLats,
              },
            },
          });

          if (typeof mapRef.current.getLayer("delivery-route") == "undefined") {
            mapRef.current.addLayer({
              id: "delivery-route",
              type: "line",
              source: "delivery-route",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#f3690d",
                "line-width": 8,
              },
            });
          } else {
            mapRef.current
              .removeLayer("delivery-route")
              .removeSource("delivery-route");
          }
        });
      }
    }


    if(mapRef.current){
      mapRef.current.flyTo([
        location.coordinates.longitude,
        location.coordinates.latitude,
      ],16);
    }
    

  }, [delivery,location]);

  return (
    <>
      {userData.loggedIn ? (
        <div className="body">
          <Header />
          <div className="addresses-wrapper">
            <SmallScreenHeader
              backButtonShown={true}
              title={"Route to product(s) destinatination."}
            />

            <div className="map">
              <div
                style={{ height: "100%" }}
                ref={mapContainerRef}
                className="map-container"
              ></div>

              {/* {
            isLoaded && coordinates[0] && delivery.results?
            <GoogleMap
            mapContainerStyle={{
              width:"100%",
              height:"100%"
            }}
            zoom={12}

            // center={{lat:coordinates[0],lng:coordinates[1]}}
            center={{lat:coordinates[0],lng:coordinates[1]}}
            onLoad={(map)=>{
              const bounds = new window.google.maps.LatLngBounds({lat:coordinates[0],lng:coordinates[1]});
              //map.fitBounds(bounds);
              setMap(map);
              setIsUpdated(!isUpdated);
            }}
            onUnmount={(map)=>{
                setMap(null);
            }}
            >
              <MarkerF 
                onClick={(e)=>console.log(e)} 
                position={{lat:coordinates[0],lng:coordinates[1]}} 
                title='Me'
                label={"Me"}
                draggable
                />

                

                {
                  delivery.results.products.map((item,index)=>{
                    // console.log(item.latitude);
                    return <MarkerF
                    position={{lat:parseFloat(item.latitude),lng:parseFloat(item.longitude)}} 
                     key={index}
                     label={{text:item.companyName,color:"var(--app-color)"}}
                     title={item.companyName}
                     onClick={(e)=>{    
                       showDirection(item.latitude,item.longitude);
                     }}
                  
                   >        
               </MarkerF>
                  })
                }

                <MarkerF 
                position={{lat:parseFloat(delivery.results.address.latitude),lng:parseFloat(delivery.results.address.longitude)}} 
                key={"dropoff"}
                label={{text:delivery.results.firstName,color:"var(--app-color)"}}
                title={delivery.results.phoneNumber}
                onClick={(e)=>{    
                  showDirection(delivery.results.address.latitude,delivery.results.address.longitude);
                }}
                 >        
                </MarkerF>
            
            </GoogleMap>
        :
        <MapContainer 
            center={[coordinates[0],coordinates[1]]} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{width:"100%",height:"100%",zIndex:1}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
            <Marker position={[coordinates[0],coordinates[1]]} icon={icon} key={"User"}>
              <Tooltip>My selected location</Tooltip>
              <Popup>My location</Popup>
            </Marker>
            
          </MapContainer>
          }  */}
            </div>
            
          </div>
          <BottomTabs />
        </div>
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default DeliveryMap;
