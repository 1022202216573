import React, { useEffect, useState,useContext } from 'react'
import { BottomTabs, CustomButton, Delivery, Header, SmallScreenHeader } from '../../../components';
import "./index.css";
import { UserContext } from '../../../contexts';
import { LoginPage } from '../../Auth';
import { APPDATAURLS } from '../../../utils/APPADATAURLS';
import { HomeCategoriesLoading } from '../../../components/Loading';
import { Button } from 'react-bootstrap';

const Deliveries = () => {
    console.table([["one"],["two"],["three"]])

    const [activeTab,setActiveTab] = useState(0);
    const [userData,setUserData] = useContext(UserContext);
    const [deliveries,setDeliveries] = useState(
        {
            loading:true,
            error:false,
            message:"",
            results:null
        }
    )
    const fetchDeliveries = ()=>{
        setDeliveries({loading:true,error:false,message:"",results:null});
        fetch(`${APPDATAURLS.getDeliveryGuyDeliveries}${userData.data.id}`,{
            method:"GET"
        })
        .then(req=>req.json())
        .then(response=>{
            setDeliveries({loading:false,error:response.error,message:response.message,results:response.data})
        }).catch(err=>{
            setDeliveries({loading:false,error:true,message:"An error occurred! Try again later.",results:null})
            console.log(err);
        });
    }
    useEffect(()=>{
        fetchDeliveries();
    },[userData.loggedIn])
  return (
    <>
    {
        userData.loggedIn?
    <div className='body' >
        <Header/>
        <div className='body-wrapper' >
        <SmallScreenHeader backButtonShown={false} title={"My deliveries"} />
        <div className='btns'>
            <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(0)}
                title={"All deliveries"}
                width={"100%"}
                backgroundColor={activeTab==0?"var(--app-color)":"#f5f5f5"}
                color={activeTab==0?"#ffffff":"#000000"}
                />
            </div>
            
             <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(1)}
                title={"Pending"}
                width={"100%"}
                backgroundColor={activeTab==1?"var(--app-color)":"#f5f5f5"}
                color={activeTab==1?"#ffffff":"#000000"}
                />
            </div>

             <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(2)}
                title={"Delievered"}
                width={"100%"}
                backgroundColor={activeTab==2?"var(--app-color)":"#f5f5f5"}
                color={activeTab==2?"#ffffff":"#000000"}
                />
            </div>

            <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(3)}
                title={"Paid"}
                width={"100%"}
                backgroundColor={activeTab==3?"var(--app-color)":"#f5f5f5"}
                color={activeTab==3?"#ffffff":"#000000"}
                />
            </div>

            <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(4)}
                title={"Unpaid"}
                width={"100%"}
                backgroundColor={activeTab==4?"var(--app-color)":"#f5f5f5"}
                color={activeTab==4?"#ffffff":"#000000"}
                />
            </div>

        </div>
        <div className='deliveries' >

            {
            deliveries.error?
            <div style={{
                width:"100%",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center"
            }}>
                <p>{deliveries.message}</p>
                <Button 
                onClick={()=>fetchDeliveries()}
                style={{backgroundColor:'var(--app-color)',borderColor:'var(--app-color)'}} 
                >Reload</Button>
            </div>:
            deliveries.loading?
            <HomeCategoriesLoading/>:
            deliveries.results?.length<0?
            <p>No deliveries found</p>:
            deliveries.results?
                activeTab==0?deliveries.results.map((item,index)=>{
                    return <Delivery key={index} item={item} />
                }):
                activeTab==1?
                deliveries.results.filter(i=>i.isDelivered==0).map((item,index)=>{
                    return <Delivery key={index} item={item} />
                }):activeTab==2?
                deliveries.results.filter(i=>i.isDelivered==1).map((item,index)=>{
                    return <Delivery key={index} item={item} />
                }):<></>:<></>
            }
        </div>
        </div>
            <BottomTabs/>
    </div>:
    <LoginPage/>
        }
        </>
  )
}

export default Deliveries;