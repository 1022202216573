import React,{useState,useEffect, useContext} from "react";


import "./index.css";
import { APPDATAURLS } from "../../utils/APPADATAURLS";
import { FavoritesContext, UserContext } from "../../contexts";
import { useCookies } from "react-cookie";
import { Advert, 
        Banner, 
        BottomTabs, 
        FloatingComponents, 
        Footer, 
        Header, 
        HeaderHero, 
        ProductsBanner, 
        SideBar, 
        SmallScreenHeader} from "../../components";
import { Button, Card, CardBody, CardGroup } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import { LoginPage } from "../Auth";
import { ProductsLoading } from "../../components/Loading";

/*
import {lazy} from "react";
const Advert =   lazy(()=>import("../../components/Advert/index"));
const Banner =   lazy(()=>import("../../components/Banner/index"));
const BottomTabs =   lazy(()=>import("../../components/BottomTabs/index"));
const Footer =   lazy(()=>import("../../components/Footer/index"));
const Header =   lazy(()=>import("../../components/Header/index"));
const HeaderHero =   lazy(()=>import("../../components/HeaderHero/index"));
const ProductsBanner =   lazy(()=>import("../../components/ProductsBanner/index"));
const SideBar =   lazy(()=>import("../../components/SideBar/index"));*/

const HomePage = ()=>{
    const [isSmallScreen,setIsSmallScreen] = useState(false);
    const [screenSize,sescreenSize] = useState(window.innerWidth);
    const [cookies,setCookies] = useCookies(["user"]);
    //console.log(cookies);
    const [userData,setUserData] = useContext(UserContext);
    const [favorites,setFavorites] = useContext(FavoritesContext);

    const [homeData,setHomeData] = useState({
        error:false,
        loading:false,
        message:"",
        results:[
        // {
        //     name:"Today's money",
        //     total:"Ksh.1200",
        //     link:"#",
        //     color:"brown"
        // },
        // {
        //     name:"Total deliveries",
        //     total:"120",
        //     link:"#",
        //     color:"indigo"
        // },
        // {
        //     name:"My balance",
        //     total:"Ksh.1212",
        //     link:"#",
        //     color:"orange"
        // },
        // {
        //     name:"Total income",
        //     total:"Ksh.23001",
        //     link:"#",
        //     color:"grey"
        // },
    ]})


    const fetchFavorites = ()=>{
        setHomeData({
            error:false,
            loading:true,
            message:"",
            results:[]
        })
    fetch(`${APPDATAURLS.deliveryHome}${userData.data.id}`,{
        method:"GET"
    }).
    then(req=>req.json()).
    then(response=>{
        setHomeData({
            error:response.error,
            loading:false,
            message:response.message,
            results:response.data
        })
    }).catch(err=>{
        console.log(err);
        setHomeData({
            error:true,
            loading:false,
            message:"An error occured",
            results:[]
        })
    })
  }

  useEffect(()=>{
    if(userData?.loggedIn){
        fetchFavorites();
    }
  },[userData])

    useEffect(()=>{
        window.addEventListener("resize",(e)=>{
                if(e.target.innerWidth<800){
                    setIsSmallScreen(true);
                }else{
                    setIsSmallScreen(false)
                }
        })

        if(screenSize<800){
            setIsSmallScreen(true);
        }else{
            setIsSmallScreen(false)
        }

    },[screenSize])
    useEffect(()=>{
        window.scroll(0,0);
    },[]);
    return (
    <>
    {
        userData?.loggedIn?
        <div className="body" >
            <Header/>
            <SmallScreenHeader title={"Home"} backButtonShown={false}/>
        <div className="body-wrapper">

            <h1 className="home-title">
                HELLO, {userData.data?.name}
            </h1>

            <CardGroup style={{
                display:"flex",
                justifyContent:"space-between",
                gap:10,
                flexWrap:"wrap"
            }} >
                

               {
                homeData.error?
                <div style={{
                    width:"100%",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center"
                }}>
                    <p>{homeData.message}</p>
                    <Button 
                    onClick={()=>fetchFavorites()}
                    style={{backgroundColor:'var(--app-color)',borderColor:'var(--app-color)'}} 
                    >Reload</Button>
                </div>:
                homeData.loading?
                <ProductsLoading/>:
                homeData.results.length<1?
                <p>No data found</p>:
                homeData.results.map((data,index)=>{
                    return <Card key={index} style={{
                                    minWidth:290,
                                    backgroundColor:data.color,
                                    color:"#ffffff",
                                    fontSize:20,
                                    flexGrow:1
                                }} >
                                    <Card.Header>
                                        {data.name}
                                    </Card.Header>
                                    <Card.Body>
                                        {data.total}
                                    </Card.Body>
                                    <Card.Footer>
                                        <FiArrowRight/>
                                </Card.Footer>
                            </Card>
                })
               }
            </CardGroup>
            <div className="cards">
                <div className="card" >

                </div>
            </div>
        </div>
        <BottomTabs/>
        <Footer/>
    </div>:
    <LoginPage/>
    }
    </>
    );
}



export default HomePage;